import {
  BeakerIcon,
  ChipIcon,
  DesktopComputerIcon,
  LightningBoltIcon,
} from '@heroicons/react/outline'

const defaultFeatures = [
  {
    name: 'Project Management',
    description:
      "From requirements gathering to product launch we help clients complete tough technical projects. Our team has experience managing large technical projects from start to finish.",
    icon: LightningBoltIcon,
  },
  {
    name: 'Scientific Research and Development',
    description:
      "We've helped organizations develop valuable IP through scientific research and development. We have extensive experience developing proprietary software, patents, and solving key research questions.",
    icon: BeakerIcon,
  },
  {
    name: 'Full-Stack Application Development',
    description: "No stack is too tall. Our team has deep expertise developing frontends, backends, and technical infrastructure.",
    icon: DesktopComputerIcon,
  },
  {
    name: 'Data Science and Machine Learning Solutions',
    description: "Do you want to make more intelligent business decisions? Do you have a complex problem that requires machine learning to solve? Our team can help you develop scalable data science and machine learning solutions.",
    icon: ChipIcon,
  },
]

export default function Features({ features = defaultFeatures }) {
  return (
    <div>
      <div className="relative max-w-7xl mx-auto py-10 lg:pt-20 lg:pb-20 px-4 sm:px-6 lg:px-8">
        <div className="relative lg:grid lg:grid-cols-5 lg:gap-x-8">

          <div className="lg:col-span-2 w-full">
            <h2 className="mx-auto lg:mx-0 text-center md:text-center lg:text-left leading-6 text-patagona-500 font-semibold font-patagona uppercase">What do we do?</h2>
            <h2 className="mt-2 text-4xl sm:text-5xl md:text-5xl mx-auto lg:mx-0 text-center md:text-center lg:text-left text-brand-dark font-patagona font-bold">
              Services
            </h2>
          </div>

          <dl className="mt-10 space-y-10 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-x-8 sm:gap-y-10 lg:mt-0 lg:col-span-3">
            {features.map((feature) => (
              <div key={feature.name}>
                <dt>
                  <div className="flex items-center mx-auto lg:mx-0 justify-center h-12 w-12 rounded-md bg-brand-dark text-white">
                    <feature.icon className="h-6 w-6" aria-hidden="true" />
                  </div>
                  <p className="mt-5 text-lg text-center md:text-center lg:text-left leading-6 font-medium text-gray-900">{feature.name}</p>
                </dt>
                <dd className="mt-2 text-base text-center md:text-center lg:text-left text-gray-500">{feature.description}</dd>
              </div>
            ))}
          </dl>

        </div>
      </div>
    </div>
  )
}

