import { HashLink as Link } from "react-router-hash-link";

import Hero from "../components/Hero";
import Partners from "../components/Partners";
import Products from "../components/Products";
import Services from "../components/Services";
import Footer from "../components/Footer";
import Contact from "../components/Contact";

function App() {
  return (
    <>
      <Hero>
        <h1 className="font-bold text-center lg:text-left text-brand-dark text-4xl sm:text-5xl md:text-6xl font-patagona">
          <span>
            We build AI-powered<br />
          </span>
          <span className="text-patagona-500">
            intelligence software
          </span>{" "}
        </h1>
        <p className="mt-3 text-center lg:text-left text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
          We are a Canadian software company that solves tough technical challenges for intelligence applications. We work with governments and companies to find solutions to emerging intelligence challenges.
          <b> Learn more and get in touch with us today.</b>
        </p>
        <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
          <div className="rounded-md shadow">
            <Link
              to="/#services"
              smooth
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-brand-dark hover:bg-patagona-800 md:py-4 md:text-lg md:px-10"
            >
              Learn more
            </Link>
          </div>
          <div className="mt-3 sm:mt-0 sm:ml-3">
            <Link
              to="/#contact"
              smooth
              className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-brand-dark bg-patagona-50 hover:bg-patagona-100 md:py-4 md:text-lg md:px-10"
            >
              Get in touch
            </Link>
          </div>
        </div>
      </Hero>
      <Partners />
      <div id="products">
        <Products />
      </div>
      <div id="services">
        <Services />
      </div>
      <div id="contact">
        <Contact />
      </div>
      <Footer />
    </>
  );
}

export default App;
