import { Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { HashLink as Link } from "react-router-hash-link";

import PatagonaLogoFullText from "./PatagonaLogoFullTextLeft";
import PatagonaLogoDark from "./PatagonaLogoDark";

const navigation = [
  { name: "PRODUCTS", href: "/#products" },
  { name: "SERVICES", href: "/#services" },
  { name: "ABOUT", href: "/about" },
  { name: "NEWS", href: "/news" },
  { name: "CONTACT", href: "/#contact" },
];

export default function Navbar() {
  return (
    <>
      <Popover>
        <div className="relative pt-8 px-4 sm:px-6 lg:px-8">
          <nav
            className="relative flex items-center justify-between sm:h-10 lg:justify-start"
            aria-label="Global"
          >
            <div className="flex flex-grow flex-shrink-0 lg:flex-grow-0">
              <div className="flex items-center justify-between w-full md:w-auto">
                <Link to="/">
                  <span className="sr-only">Patagona Technologies</span>
                  <div className="relative">
                    <PatagonaLogoFullText className="h-12 xl:h-14 w-auto fill-current text-brand-dark mb-4 xl:-ml-8" />
                  </div>
                </Link>
                <div className="flex md:hidden">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-brand-dark hover:text-brand-dark hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-patagona-500">
                    <span className="sr-only">Open main menu</span>
                    <MenuIcon className="h-10 w-10" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
            </div>
            <div className="hidden md:inline-block md:ml-12 md:pr-4 md:space-x-6 text-xl font-patagona flex items-center">
              {navigation.map((item) => (
                <Link
                  key={item.name}
                  to={item.href}
                  className="font-bold text-brand-dark hover:underline"
                  style={{
                    textDecorationThickness: ".20rem",
                  }}
                  smooth
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </nav>
        </div>

        <Transition
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            focus
            className="absolute z-10 top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
          >
            <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
              <div className="px-5 pt-6 flex items-center justify-between">
                <div>
                  <PatagonaLogoDark className="h-14 w-auto  rounded-lg overflow-hidden fill-current text-brand-dark" />
                </div>
                <div className="-mr-3">
                  <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-brand-dark hover:text-brand-dark hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-patagona-500">
                    <span className="sr-only">Close main menu</span>
                    <XIcon className="h-10 w-10" aria-hidden="true" />
                  </Popover.Button>
                </div>
              </div>
              <div className="px-2 pt-2 pb-3 space-y-1">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                  >
                    {item.name}
                  </a>
                ))}
              </div>
            </div>
          </Popover.Panel>
        </Transition>
      </Popover>
    </>
  );
}
