import GovCan from '../assets/partners/GovernmentOfCanadaMono.svg';
import DND from '../assets/partners/DepartmentOfNationalDefenceCanadaMono.svg';
import TMU from '../assets/partners/TMU-mono.svg';

export default function Example() {
  return (
    <div className="bg-patagona-50 bg-opacity-25">
      <div className="max-w-7xl mx-auto py-16 sm:py-24 px-4 sm:px-6 lg:px-8">
        <div className="lg:grid lg:grid-cols-5 lg:gap-8">
          <h2 className="col-span-2 mt-5 max-w-md mx-auto lg:mx-0 text-4xl sm:text-5xl md:text-5xl font-bold text-patagona-500 text-center lg:text-left lg:max-w-xl font-patagona">
            Partners
          </h2>
          <div className="col-span-3 flow-root self-center mt-8 lg:mt-0">
            <div className="-mt-8 -ml-8 flex flex-col sm:flex-row flex-wrap items-center justify-between lg:-ml-4">
              <div className="ml-6 mt-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0 lg:ml-4">
                <img
                  className="h-24"
                  src={GovCan}
                  alt="Government of Canada"
                />
              </div>
              <div className="ml-6 mt-8 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0 lg:ml-4">
                <img className="h-10" 
                  src={DND}
                  alt="Canadian Department of National Defence" 
                />
              </div>
              <div className="ml-6 mt-16 sm:mt-6 flex flex-grow flex-shrink-0 justify-center lg:flex-grow-0 lg:ml-4">
                <img className="h-16" src={TMU} alt="Toronto Metropolitan University" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
