import AndreiImg from '../assets/team/andrei.png'
import DavidImg from '../assets/team/david.png'

import LinkedInLogo from '../assets/team/linkedin.png'
import GithubLogo from '../assets/team/github.png'

const people = [
  {
    name: 'Andrei Betlen',
    role: 'Co-Founder, CEO',
    imageUrl: AndreiImg,
    bio: 'Before co-founding Patagona Technologies, Andrei served as the technical lead for a number of startups developing cutting edge solutions in healthcare and financial technologies. He previously served as the software team lead at Oxilight, a medical technology company, where he led the software development of two new medical devices from R&D to clinical testing. He now serves as the CEO at Patagona Technologies.',
    linkedinUrl: 'https://linkedin.com/in/andrei-betlen',
    githubUrl: 'https://github.com/abetlen',
  },
  {
    name: 'David Miller',
    role: 'Co-Founder, CTO',
    imageUrl: DavidImg,
    bio: 'David Miller is an experienced technologist with a background in Computer Science and Mathematics. Following his masters studies in Visual Computing at Universität des Saarlandes, he joined Patagona Technologies. He has worked to address challenges faced by the Canadian Department of National Defence, drawing from his experience in machine learning, high performance computing, and distributed systems development.',
    linkedinUrl: 'https://linkedin.com/in/dmille',
    githubUrl: 'https://github.com/dmille',
  },
]

export default function Example() {
  return (
    <div className="bg-white pt-4">
      <div className="mx-auto py-12 px-4 max-w-7xl sm:px-6 lg:px-8 lg:py-24">
        <div className="space-y-12 lg:grid lg:grid-cols-8 lg:gap-8 lg:space-y-0">

          <div className="space-y-5 sm:space-y-4 lg:col-span-12">
            <h2 className="leading-6 text-patagona-500 font-semibold font-patagona uppercase">Meet Our Founders</h2>
            <h2 className="text-4xl sm:text-5xl md:text-5xl font-bold font-patagona">Founder Profiles</h2>
          </div>

          <div className="lg:col-span-12">
            <ul
              className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-x-6 sm:gap-y-12 sm:space-y-0 lg:gap-x-8"
            >
              {people.map((person) => (
                <li key={person.name}>
                  <div className="space-y-4">
                    <div className="h-60 w-60">
                      <img className="object-cover shadow-lg rounded-lg" src={person.imageUrl} alt="" />
                    </div>
                    <div className="text-lg leading-6 font-medium space-y-1">
                      <h3>{person.name}</h3>
                      <p className="text-patagona-600">{person.role}</p>
                    </div>
                    <div className="text-lg">
                      <p className="text-gray-500">{person.bio}</p>
                    </div>

                    <ul className="flex space-x-5">

                      <li>
                        <a href={person.linkedinUrl} className="text-gray-400 hover:text-gray-500">
                          <span className="sr-only">LinkedIn</span>
                          <img
                            src={LinkedInLogo}
                            alt="Github"
                            style={{ maxHeight: '48px' }}
                          />
                        </a>
                      </li>

                      <li>
                        <a href={person.githubUrl} className="text-gray-400 hover:text-gray-500">
                          <span className="sr-only">Github</span>
                          <img
                            src={GithubLogo}
                            alt="Github"
                            style={{ maxHeight: '48px' }}
                          />
                        </a>
                      </li>

                    </ul>
                  </div>
                </li>
              ))}
            </ul>
          </div>

        </div>
      </div>
    </div>
  )
}

