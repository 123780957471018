import Hero from "../components/Hero";
import { useLocation, useNavigate } from "react-router-dom";

import Footer from "../components/Footer";

export default function NotFound() {
  const location = useLocation();
  const navigate = useNavigate();

  function goBack() {
    navigate(-1);
  }

  return (<>
    <Hero>
      <h1 className="font-bold text-brand-dark text-4xl sm:text-5xl md:text-5xl font-patagona">
        <span className="inline">404</span>
        <span className="text-patagona-600 xl:inline"> Page Not Found</span>
      </h1>
      <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
        The page you are looking for
        <span className="text-patagona-600 xl:inline"> {location.pathname} </span>
        does not exist.
      </p>
      <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
        <div className="rounded-md shadow">
          <button
            className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-white bg-brand-dark hover:bg-patagona-800 md:py-4 md:text-lg md:px-10"
            onClick={goBack}
          >
            Go Back
          </button>
        </div>
        <div className="mt-3 sm:mt-0 sm:ml-3">
          <a
            href="/"
            className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-brand-dark bg-patagona-50 hover:bg-patagona-100 md:py-4 md:text-lg md:px-10"
          >
            Go Home
          </a>
        </div>
      </div>
    </Hero>
    <Footer />
  </>)
}