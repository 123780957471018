import { useLocation, useNavigate } from "react-router-dom";
import React, { useState } from "react";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

const ContactForm = () => {
  let query = useQuery();
  let submitted = query.get("submitted") === "true";
  let navigate = useNavigate();
  const [formData, setFormData] = useState({});

  const handleSubmit = (e) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": e.target.name, ...formData }),
    })
      .then(() => navigate("/?submitted=true"))
      .catch((error) => alert(error));

    e.preventDefault();
  };

  const handleChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  return (
    <>
      {submitted ? (
        <div className="flex content-center justify-center h-full">
          <div className="text-patagona-500 font-bold text-lg m-auto">
            Thanks for your inquiry. We'll be in touch soon!
          </div>
        </div>
      ) : (
        <form name="contact-form" method="post" onSubmit={handleSubmit}>
          <input className="hidden" name="bot-field" />
          <input type="hidden" name="form-name" value="contact-form" />
          <div className="grid grid-cols-1 gap-y-6">
            <div>
              <label htmlFor="full-name" className="sr-only">
                Full name
              </label>
              <input
                type="text"
                name="full-name"
                required
                onChange={handleChange}
                id="full-name"
                autoComplete="name"
                className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-patagona-500 focus:border-patagona-500 border border-gray-300 rounded-md round"
                placeholder="Full name*"
              />
            </div>
            <div>
              <label htmlFor="email" className="sr-only">
                Email
              </label>
              <input
                id="email"
                name="email"
                required
                type="email"
                onChange={handleChange}
                autoComplete="email"
                className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-patagona-500 focus:border-patagona-500 border border-gray-300 rounded-md"
                placeholder="Email*"
              />
            </div>
            <div>
              <label htmlFor="phone" className="sr-only">
                Phone
              </label>
              <input
                type="text"
                name="phone"
                onChange={handleChange}
                id="phone"
                autoComplete="tel"
                className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-patagona-500 focus:border-patagona-500 border border-gray-300 rounded-md"
                placeholder="Phone"
              />
            </div>
            <div>
              <label htmlFor="message" className="sr-only">
                Message
              </label>
              <textarea
                id="message"
                name="message"
                required
                onChange={handleChange}
                rows={4}
                className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-patagona-500 focus:border-patagona-500 border border-gray-300 rounded-md"
                placeholder="Message*"
                defaultValue={""}
              />
            </div>
            <div>
              <button
                type="submit"
                className="w-full lg:w-auto inline-flex justify-center py-3 px-6 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-patagona-800 hover:bg-patagona-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-patagona-500"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      )}
    </>
  );
};

export default function Example() {
  return (
    <div className="relative bg-patagona-50 bg-opacity-25">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2" />
      </div>

      <div className="relative max-w-full lg:max-w-7xl mx-auto lg:grid lg:grid-cols-5 px-4 sm:px-6 lg:px-8">
        <div className="py-16 lg:col-span-2 lg:py-24 xl:pr-12">
          <div className="mx-auto text-center lg:text-left pr-0 lg:pr-8">
            <h2 className="mt-2 text-4xl sm:text-5xl md:text-5xl font-bold font-patagona text-patagona-500">
              Contact
            </h2>
            <p className="mt-3 text-lg leading-6 text-gray-500">
              To contact us with any enquiries about our products or services, enter your contact information below and our team will be in touch with you
              shortly.
            </p>
            <p className="mt-6 text-base text-gray-500">
              Interested in working at Patagona Technologies?<br />
              <a href="/jobs" className="font-medium text-gray-700 underline">
                View our jobs page for more information
              </a>
              .
            </p>
          </div>
        </div>

        <div className="py-16 lg:col-span-3 lg:py-24">
          <div className="h-full">
            <ContactForm />
          </div>
        </div>
      </div>
    </div>
  );
}
