import { BrowserRouter } from "react-router-dom";
import { Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import About from "./pages/About";
import News from "./pages/News";
import Jobs from "./pages/Jobs";
import DEPRAM from "./pages/DEPRAM";
import Academic from "./pages/Academic";
import TOS from "./pages/TOS";
import NotFound from "./pages/NotFound";

// function Announcement() {
//   return (
//     <>
//       <div class="relative bg-brand-dark">
//         <div class="mx-auto max-w-7xl py-3 px-3 sm:px-6 lg:px-8">
//           <div class="pr-16 sm:px-16 sm:text-center">
//             <p class="font-medium text-white">
//               <span class="md:hidden">We launched a new product! THREATDESK</span>
//               <span class="hidden md:inline">We're excited to announce our brand new product THREATDESK an integrated open-source intelligence platform.</span>
//               <span class="block sm:ml-2 sm:inline-block">
//                 <a href="https://threatdesk.io" class="font-bold text-white underline">
//                   Learn more
//                   <span aria-hidden="true"> &rarr;</span>
//                 </a>
//               </span>
//             </p>
//           </div>
//           {/* <div class="absolute inset-y-0 right-0 flex items-start pt-1 pr-1 sm:items-start sm:pt-1 sm:pr-2">
//             <button type="button" class="flex rounded-md p-2 hover:bg-indigo-500 focus:outline-none focus:ring-2 focus:ring-white">
//               <span class="sr-only">Dismiss</span>
//               <svg class="h-6 w-6 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
//                 <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
//               </svg>
//             </button>
//           </div> */}
//         </div>
//       </div>
//     </>
//   )
// }

function App() {
  return (
    <>
      <div className="w-full h-full text-brand-dark">

        {/* <div className="w-full flex justify-center items-center bg-brand-dark text-white py-2">
          <div className="flex-1 max-w-7xl w-full font-bold text-white hover:text-gray-200 px-4">
            <a href="https://threatdesk.io">
              We recently launched our open-source intelligence platform THREATDESK. Click here for more details &gt;
            </a>
          </div>
        </div> */}
        {/* <Announcement /> */}
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/news" element={<News />} />
            <Route path="/jobs" element={<Jobs />} />
            <Route path="/depram" element={<DEPRAM />} />
            <Route path="/academic" element={<Academic />} />
            <Route path="/terms" element={<TOS />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </BrowserRouter>
      </div>
    </>
  );
}

export default App;
