import Hero from "../components/Hero";
import Footer from "../components/Footer";

const articles = [
    {
        title: "Patagona Technologies Joins the Rogers Cybersecure Catalyst",
        date: "May 30th, 2022",
        url: "https://cybersecurecatalyst.ca/",
        body:
        `Patagona Technologies, a Toronto based cybersecurity company, announced its participation in the fifth cohort of the Rogers Cybersecure Catalyst, starting on May 30, 2022. As a member of the Catalyst, Patagona will collaborate with other members to develop and test innovative solutions for cybersecurity challenges faced by various sectors. The Catalyst is Canada's foremost cybersecurity accelerator, and Patagona is excited to contribute its expertise in AI and machine learning to support the Catalyst's mission of strengthening Canada's cybersecurity ecosystem.`
    },
    {
        title: "Patagona Technologies and TMU Research Team Earn Best Paper Award at Data 2022 Conference",
        date: "March 14, 2022",
        url: "https://data.scitevents.org/PreviousAwards.aspx",
        body:
            `Patagona and TMU has conducted a study on social networks, specifically Twitter, and has found that users are easily influenced by each other without knowledge of their identity. This anonymity has led to the rise of bot and hostile accounts that mimic real-user behavior, making it difficult to combat them. To address this issue, Patagona's research has focused on designing algorithms and datasets for identifying bot users. The study highlights various techniques for classifying bots, with a particular focus on the use of node and structural embedding algorithms.
        Patagona's research has demonstrated that embeddings can be used as unsupervised techniques for building features that have predictive power for identifying bots. By comparing features extracted from embeddings to other techniques such as NLP, user profile, and node-features, the study shows that embeddings can provide a unique source of predictive information. Additionally, Patagona has studied the stability of features extracted using embeddings for tasks such as bot classification by artificially introducing noise in the network. The results indicate that the degradation of classification accuracy is comparable to models trained on carefully designed node features, suggesting that embeddings are a stable source of predictive information.
        The research was awarded Best Paper Award at Data 2022, the 11th international conference on data science, technology and applications. `
    },
    {
        title: "New research to help Government of Canada fight disinformation on social media",
        date: "November 22, 2021",
        url: "https://www.torontomu.ca/math/news-events/2021/11/new-research-help-government-canada-fight-disinformation-social-media/",
        image_url: "https://www.torontomu.ca/content/dam/math/images/news-events/2021/11/Pralat-Betlen-Miller.jpg",
        body:
            `Patagona Technologies is partnering with Ryerson University professor, Pawel Pralat, to develop new machine learning tools to fight disinformation campaigns on social media. The company's founders, Andrei Betlen and David Miller, who are alumni of Ryerson University, are using their expertise in data analytics to tackle defence and security challenges under the Department of National Defence innovation program.
The solution aims to detect hostile influencers who spread disinformation campaigns on social media by combining two distinct machine learning approaches into a single super-tool that analyzes user content and underlying structure of social networks. The research expands on Pralat’s prior work in a growing area of data science: graph embeddings.
Patagona’s innovation is the first of its kind to differentiate artificial social media accounts from ordinary ones. By integrating two standalone techniques into a single tool, one searching for suspicious content and the other for abnormal network structure, the platform can identify bots and cyborgs that can propagate false information and manipulate genuine users. This new technology will provide the government with the tools and technical capabilities to limit the ability of adversarial nation-states and terrorist organizations to disrupt and undermine peacekeeping and humanitarian missions.
    `
    },
    {
        title: "Patagona Technologies Joins the IDEaS Program",
        date: "May 10, 2019",
        url: "https://vanguardcanada.com/successfully-generating-defence-and-security-ideas-from-coast-to-coast/",
        image_url: "https://vanguardcanada.com/wp-content/uploads/2019/05/Challenge-1.jpg",
        body:
            `Patagona Technologies, founded by entrepreneurs Andrei Betlen and David Miller, has been recognized for its contribution to Canada's defence and security innovation ecosystem. The Innovation for Defence Excellence and Security (IDEaS) program was launched in 2018 to invest $1.6 billion over 20 years to advance Canadian innovation. 
IDEaS has provided unique opportunities for Canadians to propose solutions to complex security problems. Patagona Technologies submitted a proposal for the IDEaS program's first call titled “What is in full motion video.” After an intensive assessment process, they were awarded a contract worth almost $150,000. The project is geared towards training a computer to recognize suspicious activities that may indicate a security threat.
The company's success highlights IDEaS' inclusive approach to encourage all Canadians to contribute to defence and security innovation, including small businesses and individuals. Patagona Technologies' success reinforces IDEaS' mandate to stimulate Canada's innovation ecosystem, advance the country's defence policy, and contribute to global peace and security.`
    }
]

export default function Page() {
    return (<>
        <Hero>
            <div className="text-left lg:max-w-lg max-w-prose w-full">
                <h2 className="leading-6 text-patagona-500 font-semibold font-patagona uppercase">Learn more</h2>
                <h3 className="mt-2 text-4xl sm:text-5xl md:text-5xl font-bold font-patagona text-gray-900">
                    News
                </h3>
            </div>
        </Hero>
        <main className="flex mt-8">
            <div className="max-w-7xl mx-auto flex justify-start items-start px-4 lg:px-8">
                <div className="flex flex-col space-y-10">
                    {articles.map(article => (
                        <article className="flex flex-col space-y-4">
                            <p className="font-patagona text-patagona-500 font-bold">Published: {article.date}</p>
                            <h2 className="text-4xl font-patagona font-bold">
                                {article.url ? (<a href={article.url}>{article.title}</a>) : (<>{article.title}</>)}
                            </h2>
                            {article.image_url && (
                                <img src={article.image_url} alt={article.title} className="max-h-96 object-cover" />
                            )}
                            <p>{article.body}</p>
                            {article.url && (
                                <a
                                    className="text-patagona-500 font-semibold font-patagona uppercase"
                                    href={article.url}>
                                    Read more
                                </a>
                            )}
                        </article>
                    ))}
                </div>
            </div>
        </main>
        <Footer />
    </>)
}