import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={324.079}
      height={324.079}
      viewBox="0 0 303.82 303.82"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path d="M0 0h303.82v303.82H0z" fill="currentColor" />
      <g fill="#fff" aria-label="PT">
        <path d="M42.802 124.93h29.992q8.922 0 13.438 2.902 4.515 2.796 6.02 8.063 1.613 5.268 1.613 14.082 0 8.385-1.506 13.652-1.397 5.268-6.02 8.385t-13.651 3.117H59.465v23.758H42.803zm24.188 36.335q4.73 0 6.772-.752 2.043-.86 2.688-3.01.645-2.258.645-7.525t-.645-7.418q-.645-2.257-2.688-3.01t-6.772-.752h-7.525v22.468zM115.69 198.89v-59.555H98.275V124.93h51.708v14.405h-17.522v59.555z" />
      </g>
      <g fill="#fff" fillRule="evenodd">
        <path d="M230.633 75.114c-4.483.58-25.961 11.053-45.33 20.84 1.948 1.353 3.887 2.759 5.86 4.147 9.665-3.03 18.442-5.673 26.486-8.005 5.745-5.44 10.944-11.385 13.825-16.705.018-.265-.275-.35-.84-.277zM179.268 186.223c-7.622 11.6-12.217 15.713-17.384 21.089-1.443 1.5-5.718 4.947-9.493 8.324-1.887 1.688-3.64 3.35-4.797 4.68-.578.665-1.007 1.25-1.221 1.672-.108.21-.157.382-.167.468-.006.052-.006.03-.006.03.037.016.121.06.262.09.238.048.594.081 1.024.09.859.02 2.02-.053 3.307-.197 2.56-.286 5.623-.855 7.929-1.507.178-.492.45-1.046.796-1.668.77-1.382 1.9-3.073 3.119-4.803 2.435-3.46 5.243-7.117 6.042-8.423 3.24-5.291 6.056-9.261 10.59-19.845zM146.2 222.486c-.025-.011-.03-.013-.003.02.005.005.003-.02.003-.02zm27.658-30.421c-5.205 5.737-8.842 8.408-12.879 11.792-1.49 1.25-5.704 3.843-9.47 6.487-1.884 1.323-3.643 2.646-4.832 3.76-.595.556-1.046 1.066-1.29 1.455-.243.389-.233.579-.216.615-.024-.052.046.056.251.163.205.107.52.226.905.337.703.204 1.653.384 2.708.53.808-.779 1.69-1.59 2.604-2.408 3.804-3.405 8.175-6.956 9.432-8.264 4.091-4.257 7.736-7.622 12.787-14.468z" />
        <path d="M284.798 77.98c-10.042-.031-38.047 5.26-93.735 22.735l.024.035-.04-.035h.016l-.095-.067c-.08-.065-.155-.137-.235-.202-2.867-2.327-5.944-4.484-9.216-6.368-5.134-3.305-10.477-5.945-16.873-6.649-.654.027 2.317-1.787 1.664-1.752-5.044.284-13.602 1.032-17.48 1.741-9.632 1.642-18.88 5.684-26.703 14.607-5.81 3.423-29.535 4.76-35.573 10.041 8.972-2.475 34.293-2.973 42.436-2.362 41.314 3.103 59.26 32.645 49.971 78.631-4.078 9.226-6.775 13.084-9.805 18.032-1.716 2.802-11.116 14.426-10 15.946.715.974 9.54-2.518 10.93-4.439 23.667-34.248 50.032-55.416 39.58-89.958l-.012-.04c.01-.004 24.363-8.975 44.373-25.355 19.72-16.143 31.881-18.718 34.894-22.468.99-1.233-.19-2.06-4.119-2.073z" />
      </g>
    </svg>
  )
}

export default SvgComponent

