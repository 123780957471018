import { ChevronRightIcon } from "@heroicons/react/solid";
import React, { useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import illustration from "../assets/depram-illustration.svg";
import Footer from "../components/Footer";

function useQuery() {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default function Example() {
  let query = useQuery();
  let navigate = useNavigate();
  let submitted = query.get("submitted") === "true";
  const [formData, setFormData] = useState({});
  const handleSubmit = (e) => {
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": e.target.name, ...formData }),
    })
      .then(() => navigate("/depram/?submitted=true"))
      .catch((error) => alert(error));
    e.preventDefault();
  };

  const handleChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const emailInput = useRef(null);

  function handleClickJoinBeta() {
    emailInput.current.focus();
  }
  return (
    <div className="bg-white">
      <div className="relative overflow-hidden">
        <div className="flex flex-col min-h-screen">
          <div className="bg-gray-900 pt-6">
            <nav
              className="relative max-w-7xl mx-auto flex items-center justify-between px-4 sm:px-6"
              aria-label="Global"
            >
              <div className="flex items-center flex-1">
                <div className="flex items-center justify-between w-full md:w-auto">
                  <a href="/depram">
                    <span className="sr-only">DEPRAM</span>
                    <div className="text-white font-patagona tracking-wider font-bold text-5xl">
                      DEPRAM
                    </div>
                  </a>
                </div>
              </div>
            </nav>
          </div>
          <main className="lg:h-full flex-1 flex flex-col">
            <div className="flex-1 overflow-hidden pt-10 bg-gray-900 sm:pt-16 lg:pt-4 lg:overflow-hidden">
              <div className="mx-auto  max-w-7xl lg:px-8 lg:mt-8">
                <div className="lg:grid  lg:grid-cols-2 lg:gap-8">
                  <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 sm:text-center lg:px-0 lg:text-left lg:flex lg:items-center">
                    <div className="lg:py-24">
                      <button
                        onClick={handleClickJoinBeta}
                        className="inline-flex items-center text-white bg-black rounded-full p-1 pr-2 sm:text-base lg:text-sm xl:text-base hover:text-gray-200"
                      >
                        <span className="px-3 py-0.5 text-white text-xs font-semibold leading-5 uppercase tracking-wide bg-gradient-to-r from-patagona-500 to-cyan-600 rounded-full">
                          Coming soon
                        </span>
                        <span className="ml-4 text-sm">
                          Sign up for updates
                        </span>
                        <ChevronRightIcon
                          className="ml-2 w-5 h-5 text-gray-500"
                          aria-hidden="true"
                        />
                      </button>
                      <h1 className="mt-4 text-4xl tracking-tight font-extrabold text-white sm:mt-5 sm:text-6xl lg:mt-6 xl:text-6xl">
                        <span className="block">Connect the dots on</span>
                        <span className="pb-3 block bg-clip-text text-transparent bg-gradient-to-r from-patagona-200 to-cyan-400 sm:pb-5">
                          online discussions
                        </span>
                      </h1>
                      <p className="text-base text-gray-300 sm:text-xl lg:text-lg xl:text-xl">
                        Gain access to actionable intelligence through automated
                        information operation detection, real-time threat
                        visualization and hostile maneuver simulation.
                      </p>
                      <div className="lg:h-24">
                        {submitted ? (
                          <div className="mt-10 sm:mt-12 text-patagona-400 font-bold text-xl">
                            Thanks for signing up. We'll be in touch soon!
                          </div>
                        ) : (
                          <div className="mt-10 sm:mt-12">
                            <form
                              name="email-form"
                              method="post"
                              onSubmit={handleSubmit}
                              className="sm:max-w-xl sm:mx-auto lg:mx-0"
                            >
                              <input
                                type="hidden"
                                name="form-name"
                                value="email-form"
                              />
                              <input className="hidden" name="bot-field" />
                              <div className="sm:flex">
                                <div className="min-w-0 flex-1">
                                  <label htmlFor="email" className="sr-only">
                                    Email address
                                  </label>
                                  <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    required
                                    ref={emailInput}
                                    onChange={handleChange}
                                    placeholder="Enter your email"
                                    className="block w-full px-4 py-3 rounded-md border-0 text-base text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-400 focus:ring-offset-gray-900"
                                  />
                                </div>
                                <div className="mt-3 sm:mt-0 sm:ml-3">
                                  <button
                                    type="submit"
                                    className="block w-full py-3 px-4 rounded-md shadow bg-gradient-to-r from-patagona-500 to-cyan-600 text-white font-medium hover:from-patagona-600 hover:to-cyan-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-400 focus:ring-offset-gray-900"
                                  >
                                    Sign up
                                  </button>
                                </div>
                              </div>
                              <p className="mt-3 text-sm text-gray-300 sm:mt-4">
                                By providing your email, you agree to our{" "}
                                <a
                                  href="/terms"
                                  className="font-medium text-white"
                                >
                                  terms or service
                                </a>
                                .
                              </p>
                            </form>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-12 -mb-16 sm:-mb-48 lg:m-0 lg:relative">
                    <div className="mx-auto max-w-md px-4 sm:max-w-2xl sm:px-6 lg:max-w-none lg:px-0">
                      {/* Illustration taken from Lucid Illustrations: https://lucid.pixsellz.io/ */}
                      {/* src="https://tailwindui.com/img/component-images/cloud-illustration-teal-cyan.svg" */}
                      <img
                        className="w-full lg:absolute lg:inset-y-0 lg:left-0 lg:h-full lg:w-auto lg:max-w-none"
                        src={illustration}
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
        <Footer />
      </div>
    </div>
  );
}
