import Hero from "../components/Hero";
import Footer from "../components/Footer";

function App() {
  return (
    <>
      <Hero
        imageUrl={
          "https://images.unsplash.com/photo-1506905925346-21bda4d32df4?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2070&q=80"
        }
      >
        <div className="text-left">
          <h2 className="leading-6 text-patagona-500 font-semibold font-patagona uppercase">
            Work for us
          </h2>
          <h3 className="mt-2 text-4xl sm:text-5xl md:text-5xl font-bold font-patagona text-gray-900 sm:text-4xl">
            Jobs
          </h3>
          <p className="mt-8 text-lg text-gray-500">
            We are always on the look out for top talent. If you love solving
            hard problems, contact us at{" "}
            <a
              href="mailto:jobs@patagona.ca"
              className="underline text-patagona-500"
            >
              jobs@patagona.ca
            </a>
            .
          </p>
        </div>
      </Hero>
      <Footer />
    </>
  );
}

export default App;
