import Hero from "../components/Hero";
import Footer from "../components/Footer";
import Team from "../components/Team";

function App() {
  return (
    <>
      <Hero imageUrl={"https://images.unsplash.com/photo-1454496522488-7a8e488e8606?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1934&q=80"}>
        <div className="text-left lg:max-w-lg max-w-prose w-full">
          <h2 className="leading-6 text-patagona-500 font-semibold font-patagona uppercase">Learn more</h2>
          <h3 className="mt-2 text-4xl sm:text-5xl md:text-5xl font-bold font-patagona text-gray-900">
            About Us
          </h3>
          <p className="mt-8 text-lg text-gray-500">
            Patagona Technologies is a Toronto-based software development company founded by two computer science researchers with a clear mission to develop technologies that can provide real-time solutions to complex problems. Our vision is to unlock the full potential of data through comprehensive solutions and make it safe for everyone to use.
          </p>
          <p className="mt-8 text-lg text-gray-500">
            We started our journey by collaborating with the Canadian Department of National Defence to create scientific solutions to combat online hostile information. Since then, we have extensively worked with the Government of Canada to make the digital world safer for Canadians. In addition, we have partnered with fintech and healthcare companies to create proprietary software that has increased their operational efficiency.
          </p>
        </div>
      </Hero>
      <Team />
      <Footer />
    </>
  );
}

export default App;
