import THREATDESK from '../assets/threatdesk.svg';

export default function Page() {
    return (
        <div className="bg-gray-900 text-white">
            <div className="relative max-w-7xl mx-auto py-10 lg:pt-20 lg:pb-20 px-4 sm:px-6 lg:px-8">
                <div className="relative lg:grid lg:grid-cols-5 lg:gap-x-8 mt-4">
                    <div className="col-span-3 text-center lg:text-left">
                        <div>
                            <h2 className="mx-auto lg:mx-0 text-center md:text-center lg:text-left leading-6 text-patagona-500 font-semibold font-patagona uppercase">Products</h2>
                            <h1 className="text-center md:text-center lg:text-left text-white font-patagona font-bold text-4xl sm:text-5xl md:text-5xl ">THREATDESK</h1>
                        </div>
                        <div className="mt-4">
                            THREATDESK is an AI-powered threat intelligence platform. It uses machine learning to automatically collect, analyze, and provide actionable insights on threats from a variety of online sources. THREATDESK provides a suite of tools to help organizations investigate and respond to threats.
                            <div className="font-bold sm:font-normal block sm:inline mt-4 sm:mt-0 text-lg sm:text-base">
                                THREATDESK Features
                            </div>
                        </div>
                        <div className="mt-4 flex justify-center lg:justify-start">
                            <ul className="sm:list-disc list-inside pl-4 text-center sm:text-left gap-y-2 sm:gap-y-0 flex flex-col">
                                <li>Automated Data Collection from Thousands of Online Sources</li>
                                <li>Realtime Curated Threat Feeds with Actionable Intelligence</li>
                                <li>AI-powered Workflows to Reduce Operational Costs</li>
                            </ul>
                        </div>
                        <div className="mt-8">
                            <a href="https://threatdesk.io" className="mt-4 bg-patagona-500 hover:bg-patagona-600 text-white font-bold py-2 px-4 rounded">
                                Learn More
                            </a>
                        </div>
                    </div>
                    <div className="col-span-2 w-full flex justify-center mt-8 lg:mt-0 pl-2">
                        <img className="h-96 w-96"
                            src={THREATDESK}
                            alt="THREATDESK"
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}